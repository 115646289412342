import logo from './logo.svg';
import './App.css';

import { useSDK } from "@metamask/sdk-react";
import React from "react";
import { useState } from 'react';

function App() {
  const [account, setAccount] = useState("");
  const { sdk, connected, connecting, provider, chainId } = useSDK();

  const connect = async () => {
    try {
      const accounts = await sdk?.connect();
      setAccount(accounts?.[0]);
    } catch (err) {
      console.warn("failed to connect..", err);
    }
  };

  return (
    <div className="App">
      <button style={{ padding: 10, margin: 10 }} onClick={connect}>
        Connect
      </button>
      {connected && (
        <div>
          <>
            {chainId && `Connected chain: ${chainId}`}
            <p></p>
            {account && `Connected account: ${account}`}
            <p>Please Create Pool, then Initialize</p>
            <p>Create Pool:</p>
            <button onClick={() => {
              provider.request({
                method: "eth_sendTransaction",
                params: [{
                  from: account,
                  data: "0xa16712950000000000000000000000007b0df1cd724ec34ec9bc4bd19749b01afb490761000000000000000000000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb480000000000000000000000000000000000000000000000000000000000002710",
                  to: "0x1F98431c8aD98523631AE4a59f267346ea31F984"
                }]
              })
            }}>Create</button>
            <p>Initialize Pool:</p>
            <button onClick={() => {
              provider.request({
                method: "eth_sendTransaction",
                params: [{
                  from: account,
                  data: "0xf637731d000000000000000000000000000000000000000001160bb2fff69515502fc4a6",
                  to: "0xf4E43a4A17d2820C7CF724e46844943931a47894"
                }]
              })
            }}>Initialize</button>
          </>
        </div>
      )}
    </div>
  );
}

export default App;
